.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 1;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  pointer-events: auto;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}

.swiper-pagination.swiper-pagination {
  text-align: center;
  margin-top: 50px;
  position: inherit;
}

.swiper-pagination-bullet.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #fff;
  border: 1px solid #e60000;
  opacity: inherit !important;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -webkit-transition-delay: 0.5s, 0.5s, 0s;
  -o-transition-delay: 0.5s, 0.5s, 0s;
  transition-delay: 0.5s, 0.5s, 0s;
  cursor: pointer;
  @media (min-width: 46rem) {
    width: 10px;
    height: 10px;
  }
  @media (min-width: 64rem) {
    width: 10px;
    height: 10px;
  }
}

.swiper-pagination-bullet-exp.swiper-pagination-bullet-exp {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: transparent;
  border: 1px solid #ffffff;
  opacity: inherit !important;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -webkit-transition-delay: 0.5s, 0.5s, 0s;
  -o-transition-delay: 0.5s, 0.5s, 0s;
  transition-delay: 0.5s, 0.5s, 0s;
  cursor: pointer;
  @media (min-width: 46rem) {
    width: 8px;
    height: 8px;
  }
  @media (min-width: 64rem) {
    width: 8px;
    height: 8px;
  }
}

.swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  opacity: 1;
  background: #e60000;
  width: 32px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  @media (min-width: 46rem) {
    width: 40px;
  }
  @media (min-width: 64rem) {
    width: 40px;
  }
}

.swiper-pagination-bullet-active-exp.swiper-pagination-bullet-active-exp {
  opacity: 1;
  background: white;
  width: 32px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

